import React, { useEffect, useRef, useState } from "react";
import '../../styles/animations.css';
import primeraImagen from '../../../src/Finca/PrimeraImagen.jpeg';

function NewsLetter() {
  const videoRef = useRef(null);
 /*  const [showPoster, setShowPoster] = useState(true); */

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadeddata', () => {
        videoRef.current.currentTime = 0.3;
        // Cuando se carga el video, ocultamos la imagen inicial
      /*   setShowPoster(false); */
      });
    }
  }, []);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };
  // Función para determinar si la pantalla es pequeña (menor a 600px de ancho)
  const isSmallScreen = () => {
    return window.innerWidth < 600;
  };

  return (
    <div className="Home NewsLetter" id="argentina-scroll-animate-main">
      <div className="linea-dorada"></div>
      <section className="NewsLetter-title">
        <h1>Novedades Julio 2024</h1>
      </section>
      
   
        <div className="video-container"
      /*   style={{
          backgroundImage: showPoster ? 'url("/public/Images/home/PrimeraImagen.jpeg")' : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: isSmallScreen() ? '100%' : 'auto', 
          height: isSmallScreen() ? 'auto' : 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }} */
        
        >
         
          <video
            ref={videoRef}
            id="megavideofinca"
            controls
            style={{ display: /* showPoster ? 'none' : */ 'block',
            width: isSmallScreen() ? '100%' : 'auto', 
            height: isSmallScreen() ? 'auto' : 'auto',

          }}
          >
            <source
              src="https://gmfp.createch.com.ar/api/newsletter"
              type="video/mp4"
            />
          </video>
        </div>


        
        <section id="fincaPropia"></section>
        <section className="NewsLetter-video-buttons">
          <button onClick={handlePlay}>Reproducir</button>
          <button onClick={handlePause}>Pausar</button>
        </section>
        <div className="linea-dorada"></div>
        <section id="fincaPropia"></section>
  
    </div>
  );
}

export default NewsLetter;





/* import React, { useEffect, useRef, useState } from "react";
import '../../styles/animations.css';

function NewsLetter() {
  console.log('Entrando a NewsLetter--->');
  const videoRef = useRef(null);


  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.log('Error intentando reproducir el video automaticamente:', error);
      });
    }
  }, []);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handlePause = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  return (
    <div className="Home NewsLetter" id="argentina-scroll-animate-main">
      <div className="linea-dorada"></div>
      <section className="NewsLetter-title">
        <h1>Novedades Julio 2024</h1>
      </section>
      
      <div className="wrapper-parallax">
        <div className="parallax-container">
          <video ref={videoRef} id="megavideofinca" autoPlay controls >
            <source
              src="https://gmfp.createch.com.ar/api/newsletter"
              type="video/mp4"
            />
          </video>
        </div>
        <section id="fincaPropia"></section>
        <section className="NewsLetter-video-buttons">
          <button onClick={handlePlay}>Play</button>
          <button onClick={handlePause}>Pause</button>
        </section>
        <div className="linea-dorada"></div>
        <section id="fincaPropia"></section>
      </div>
    </div>
  );
}

export default NewsLetter; */





/* import React, { useEffect, useRef } from "react";
import '../../styles/animations.css';

function NewsLetter() {
  console.log('Entrando a NewsLetter--->');
    const videoRef = useRef(null);
  
    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, []);
  
    const handlePlay = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };
  
    const handlePause = () => {
      if (videoRef.current) {
        videoRef.current.pause();
      }
    };

  return (
    <div className="Home NewsLetter" id="argentina-scroll-animate-main">
      <div className="linea-dorada"></div>
      <section className="NewsLetter-title">
        <h1> Novedades Julio 2024</h1>
      </section>
      
      <div className="wrapper-parallax">
        <div className="parallax-container">
          <video ref={videoRef} id="megavideofinca" autoPlay >
            <source
              src="https://gmfp.createch.com.ar/api/newsletter"
              type="video/mp4"
            />
          </video>
        </div>
        <section id="fincaPropia"></section>
        <section className="NewsLetter-video-buttons">
          <button onClick={handlePlay}>Play</button>
          <button onClick={handlePause}>Pause</button>
        </section>
        <div className="linea-dorada"></div>
        <section id="fincaPropia">
        </section>

      </div>
    </div>
  );
}

export default NewsLetter;
 */