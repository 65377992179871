import React from 'react';


const Navegar3 = ({ to, children }) => {
  return <a href={to}>{children}</a>;
};

export default Navegar3;





/* import { Link, useNavigate } from 'react-router-dom';

const Navegar2 = ({ to, children }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    navigate(to);
  };

  return <Link to={to} onClick={handleClick}>{children}</Link>;
};

export default Navegar2; */

